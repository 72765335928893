import './index.scss';
import { Modal, Button } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import * as summaryReducer from '../../../../redux/reducers/summaryReducer';
import * as submissionReducer from '../../../../redux/reducers/submissionReducer';
import { FaFileUpload, FaRegTrashAlt } from 'react-icons/fa';
import { ChangeEvent, useState } from 'react';
import { FileExtended } from '../../../../interface/file-extended';
import { FileStatus } from '../../../../enum/file-status';
import { GoDotFill } from 'react-icons/go';
import { MdRefresh } from 'react-icons/md';
import { selectFileIcon } from '../../attachment-tab';
import { formatBytes, formatFileName } from '../../../../helpers/file-helper';
import { SubmissionDetailsDTO } from '../../../../interface/submission-details-dto';
import { getMimeType } from '../../../../helpers/submission-helper';
import AlertComponent from '../../../../components/ui/alert';
import uploadService from '../../../../resources/upload/upload.service';
import { SubmissionStatus } from '../../../../enum/submission-status';
import { Tooltip } from 'react-tooltip';

export default function UploadNewFileModal() {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [fileForReview, setFileForReview] = useState<FileExtended | null>(null);

  const { showUploadNewFileModal } = useAppSelector(state => state.summary);
  const currentRecord: SubmissionDetailsDTO = useAppSelector(state => state.submission.currentRecord);
  const dispatch = useAppDispatch();

  function onClose() {
    dispatch(summaryReducer.setShowUploadNewFileModal(false));
  }

  function handleDrop(event: React.DragEvent<HTMLDivElement>) {
    event.preventDefault();

    if (fileForReview?.status === FileStatus.UPLOADED) {
      return;
    }

    const droppedFile = event && event.dataTransfer && event.dataTransfer.files ? event.dataTransfer.files[0] : null;

    setFile(droppedFile);
  }

  function handleFileChange(event: ChangeEvent<HTMLInputElement>) {
    event.preventDefault();
    const file = event && event.target && event.target.files ? event.target.files[0] : null;

    setFile(file);
  }

  function setFile(file: File | null) {
    setErrorMessage('');

    if (file) {
      const newFile: FileExtended = {
        file,
        progress: 0,
        status: FileStatus.PENDING,
      };

      setFileForReview(newFile);
    }
  }

  function removeFile(): void {
    if (fileForReview) {
      setFileForReview(null);
    }
  }

  function refreshUpload(fileExtended: FileExtended) {
    uploadFile(fileExtended);
  }

  function uploadFile(fileExtended: FileExtended | null) {
    if (!fileExtended) {
      return;
    }

    setErrorMessage('');

    updateFileStatus(fileExtended, FileStatus.UPLOADING);
    fileExtended.status = FileStatus.UPLOADING;

    uploadService
      .signFile(currentRecord.submissionId, fileExtended.file.name)
      .promise.then((res: any) => {
        const signedUrl: string = res.signedUrl;
        const xhr = new XMLHttpRequest();

        xhr.open('PUT', signedUrl, true);
        xhr.setRequestHeader('Content-Type', getMimeType(fileExtended.file.name));

        xhr.upload.onprogress = event => updateFileProgress(event, fileExtended);

        xhr.onload = () => {
          if (xhr.status === 200) {
            const newUrl = signedUrlToGcsPath(signedUrl);

            uploadService.gcsPathUpdate(currentRecord.submissionId, newUrl);
            setSuccessMessage('Success! The file has been uploaded successfully.');
            updateFileStatus(fileExtended, FileStatus.UPLOADED);
            fileExtended.status = FileStatus.UPLOADED;
            dispatch(
              submissionReducer.setCurrentRecord({ ...currentRecord, submissionStatus: SubmissionStatus.PROCESSING }),
            );

            setTimeout(() => {
              dispatch(summaryReducer.setShowUploadNewFileModal(false));
            }, 5000);
          } else {
            updateFileStatus(fileExtended, FileStatus.FAILED);
            fileExtended.status = FileStatus.FAILED;
          }
        };

        xhr.onerror = () => {
          updateFileStatus(fileExtended, FileStatus.FAILED);
          fileExtended.status = FileStatus.FAILED;
        };

        xhr.send(fileExtended.file);
      })
      .catch(error => {
        const errorMessage: string = error.response.data.message;

        if (errorMessage && errorMessage.includes('Attachment already exists')) {
          fileExtended.error = 'Duplicate';
          setErrorMessage('Duplicate files need to be renamed');
        } else {
          console.error(error);
        }

        updateFileStatus(fileExtended, FileStatus.FAILED);
        fileExtended.status = FileStatus.FAILED;
      });
  }

  function signedUrlToGcsPath(url: string): string {
    let transformedUrl = url.replace('https://storage.googleapis.com/', 'gs://');

    // Remove everything after the "?" (including the "?" itself)
    transformedUrl = transformedUrl.split('?')[0];

    return transformedUrl;
  }

  function updateFileStatus(fileExtended: FileExtended, status: string) {
    setFileForReview({ ...fileExtended, status });
  }

  function updateFileProgress(event: ProgressEvent<EventTarget>, fileExtended: FileExtended) {
    if (event.lengthComputable) {
      const percentComplete = Math.round((event.loaded / event.total) * 100);
      fileExtended.progress = percentComplete;

      setFileForReview({ ...fileExtended });
    }
  }

  function cancelUpload() {
    dispatch(summaryReducer.setShowUploadNewFileModal(false));
    setFileForReview(null);
    setSuccessMessage('');
  }

  return (
    <Modal
      className="upload-new-file-modal"
      show={showUploadNewFileModal}
      onHide={() => onClose()}
      aria-labelledby="UploadNewFileModal">
      <Modal.Header closeButton>
        <Modal.Title className="modalTitle" id="UploadNewFileModal">
          Upload File for Review
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modalBody">
        <div className="upload-new-file-content">
          {successMessage && (
            <AlertComponent text={successMessage} type="success" onClick={() => setSuccessMessage('')} buttonText="X" />
          )}
          {errorMessage && (
            <AlertComponent text={errorMessage} type="danger" onClick={() => setErrorMessage('')} buttonText="X" />
          )}
          <div className="upload-drop-zone" onDrop={handleDrop} onDragOver={event => event.preventDefault()}>
            <FaFileUpload size={24} color="#5E677B" />
            <div className="upload-drop-zone-text">
              <span>
                Drag & drop your file here or &nbsp;
                <label htmlFor="new-review-file" className="upload-choose-file-button">
                  choose file
                </label>
                <input
                  className="upload-choose-file-input"
                  type="file"
                  id="new-review-file"
                  disabled={fileForReview?.status === FileStatus.UPLOADED}
                  onChange={handleFileChange}
                />
                .
              </span>
            </div>
          </div>
          <div className="upload-file-container">
            {fileForReview && (
              <div className="files-upload-card">
                <div className="files-upload-card-body">
                  {selectFileIcon(fileForReview.file.type)}
                  <div className="files-upload-card-content">
                    <span
                      className="files-upload-card-title"
                      data-tooltip-id="review-file-name-tooltip"
                      data-tooltip-content={fileForReview.file.name.length > 45 ? fileForReview.file.name : ''}>
                      {formatFileName(fileForReview.file.name)}
                    </span>
                    <div className="files-upload-card-infos">
                      <span className="files-upload-card-info">{formatBytes(fileForReview.file.size, 1)}</span>
                      {fileForReview.status === FileStatus.FAILED && (
                        <>
                          <GoDotFill size={6} color="#5E677B" />
                          <span className="files-upload-card-error">{`${
                            fileForReview.error ? fileForReview.error : 'Upload failed'
                          }`}</span>
                        </>
                      )}
                      {fileForReview.status === FileStatus.UPLOADED && (
                        <>
                          <GoDotFill size={6} color="#5E677B" />
                          <span className="files-upload-card-completed">Upload completed</span>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="files-upload-card-actions">
                    {fileForReview.status !== FileStatus.UPLOADING && fileForReview.status !== FileStatus.UPLOADED && (
                      <FaRegTrashAlt size={18} color="#5E677B" onClick={removeFile} />
                    )}
                    {fileForReview.status === FileStatus.FAILED && (
                      <MdRefresh size={22} color="#5E677B" onClick={() => refreshUpload(fileForReview)} />
                    )}
                  </div>
                </div>
                {fileForReview.status === FileStatus.UPLOADING && (
                  <div className="progress-bar-track">
                    <div
                      id="myBar"
                      className="progress-bar"
                      style={{
                        width: `${fileForReview.progress || 0}%`,
                      }}></div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <Tooltip id="review-file-name-tooltip" place="top" />
      </Modal.Body>
      <Modal.Footer>
        <div className="actions">
          <Button
            className="button cancel-button"
            onClick={cancelUpload}
            disabled={fileForReview?.status === FileStatus.UPLOADED}>
            Cancel
          </Button>
          <Button
            className="button upload-button"
            onClick={() => uploadFile(fileForReview)}
            disabled={fileForReview?.status === FileStatus.UPLOADED || !fileForReview}>
            Upload
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
