import { IoIosClose } from 'react-icons/io';
import './index.scss';
import SelectComponent from '../../../components/ui/select-component';
import { IOption } from '../../../components/ui/multi-select-dropdown';
import { useEffect, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import LoaderComponent from '../../../components/ui/loader';
import contentCategoriesService from '../../../resources/content-categories/content-categories.service';
import {
  CategoryTypes,
  ContentCategory,
  SubmissionContentCategoriesDTO,
  SubmissionContentCategory,
} from '../../../resources/content-categories/content-categories-types';
import { SubmissionDetailsDTO } from '../../../interface/submission-details-dto';
import { useAppSelector } from '../../../redux/hooks';
import AlertComponent from '../../../components/ui/alert';

interface SubmissionDetailsFormState {
  [key: string]: IOption | string;
}

const defaultCategoriesFormState = {
  primary: { value: '', label: '' },
  primaryOther: '',
  secondary: { value: '', label: '' },
  secondaryOther: '',
};

interface CategoryTabProps {
  open: boolean;
  onClose: () => void;
}

export default function CategoriesTab({ open, onClose }: CategoryTabProps) {
  const [categoriesData, setCategoriesData] = useState<SubmissionDetailsFormState>(defaultCategoriesFormState);
  const [isRequesting, setIsRequesting] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [contentCategories, setContentCategories] = useState<ContentCategory[]>();
  const [submissionContentCategories, setSubmissionContentCategories] = useState<SubmissionContentCategory[]>();

  const currentRecord: SubmissionDetailsDTO = useAppSelector(state => state.submission.currentRecord);

  useEffect(() => {
    if (!contentCategories || contentCategories.length === 0) {
      fetchContentCategories();
    }
  }, []);

  useEffect(() => {
    fetchSubmissionContentCategories();
  }, [currentRecord]);

  useEffect(() => {
    buildSubmissionContentCategories();
  }, [contentCategories, submissionContentCategories, currentRecord]);

  const allContentCategories = useMemo<IOption[]>(() => {
    if (!contentCategories) {
      return [] as IOption[];
    }

    const contentCategoriesOptions = contentCategories.map((contentCategory: ContentCategory) => ({
      value: contentCategory.id.toString(),
      label: contentCategory.name,
    })) as IOption[];

    return contentCategoriesOptions;
  }, [contentCategories]);

  function buildSubmissionContentCategories() {
    setCategoriesData({
      primary: {
        value: getCategoryDataValue(CategoryTypes.primary),
        label: getCategoryDataLabel(CategoryTypes.primary),
      },
      primaryOther: getCurrentOtherComment(CategoryTypes.primary) || '',
      secondary: {
        value: getCategoryDataValue(CategoryTypes.secondary),
        label: getCategoryDataLabel(CategoryTypes.secondary),
      },
      secondaryOther: getCurrentOtherComment(CategoryTypes.secondary) || '',
    });
  }

  function getCategoryDataValue(categoryType: CategoryTypes): string {
    const mappedCategories = submissionContentCategories
      ?.filter(
        (submissionContentCategory: SubmissionContentCategory) =>
          submissionContentCategory.categoryType === categoryType,
      )
      .map((submissionContentCategory: SubmissionContentCategory) => submissionContentCategory.categoryId)
      .join(', ');

    return mappedCategories || '';
  }

  function getCategoryDataLabel(categoryType: CategoryTypes): string {
    const mappedCategories = submissionContentCategories
      ?.filter(
        (submissionContentCategory: SubmissionContentCategory) =>
          submissionContentCategory.categoryType === categoryType,
      )
      .map((submissionContentCategory: SubmissionContentCategory) =>
        getCategoryName(submissionContentCategory.categoryId),
      )
      .join(', ');

    return mappedCategories || '';
  }

  function getCurrentOtherComment(categoryType: CategoryTypes): string | undefined {
    const otherContentCategory = submissionContentCategories?.filter(
      (submissionContentCategory: SubmissionContentCategory) =>
        getCategoryName(submissionContentCategory.categoryId) === 'Other' &&
        submissionContentCategory.categoryType === categoryType,
    );

    return otherContentCategory?.at(0)?.comment || undefined;
  }

  function getCategoryName(categoryId: number): string | null {
    const category = contentCategories?.find((contentCategory: ContentCategory) => contentCategory.id === categoryId);

    return category?.name || null;
  }

  function fetchContentCategories() {
    contentCategoriesService.getContentCategories().promise.then((contentCategories: ContentCategory[]) => {
      setContentCategories(contentCategories);
    });
  }

  function fetchSubmissionContentCategories() {
    contentCategoriesService
      .getContentCategoriesBySubmissionId(currentRecord.submissionId)
      .promise.then((submissionContentCategories: SubmissionContentCategory[]) => {
        setSubmissionContentCategories(submissionContentCategories);
      });
  }

  function saveCategories() {
    setErrorMessage('');

    const primaryOther = (categoriesData.primaryOther as string).trim();
    const secondaryOther = (categoriesData.secondaryOther as string).trim();
    const hasPrimaryOtherCategory = (categoriesData.primary as IOption).label.includes('Other');
    const hasSecondaryOtherCategory = (categoriesData.secondary as IOption).label.includes('Other');

    const submissionContentCategoriesDTO: SubmissionContentCategoriesDTO = {
      primaryCategories: getCategoryValues((categoriesData.primary as IOption).value),
      primaryOther: primaryOther || undefined,
      secondaryCategories: getCategoryValues((categoriesData.secondary as IOption).value),
      secondaryOther: secondaryOther || undefined,
    };

    const existingCategoriesDTO: SubmissionContentCategoriesDTO = {
      primaryCategories: getCategoryValues(getCategoryDataValue(CategoryTypes.primary)),
      primaryOther: getCurrentOtherComment(CategoryTypes.primary),
      secondaryCategories: getCategoryValues(getCategoryDataValue(CategoryTypes.secondary)),
      secondaryOther: getCurrentOtherComment(CategoryTypes.secondary),
    };

    // Check if there are any differences
    const hasChanges = JSON.stringify(submissionContentCategoriesDTO) !== JSON.stringify(existingCategoriesDTO);

    if (!hasChanges) {
      setErrorMessage('No changes were made to your categories. Please update the fields before saving.');
      return;
    }

    if (
      (hasPrimaryOtherCategory && primaryOther !== undefined && primaryOther.length < 3) ||
      (hasSecondaryOtherCategory && secondaryOther !== undefined && secondaryOther.length < 3)
    ) {
      setErrorMessage('All "Other" fields must be at least 3 characters long.');
      return;
    }

    setIsRequesting(true);

    contentCategoriesService
      .createSubmissionContentCategories(currentRecord.submissionId, submissionContentCategoriesDTO)
      .promise.then((submissionContentCategories: SubmissionContentCategory[]) => {
        setSuccessMessage('Categories saved successfully.');
        setSubmissionContentCategories(submissionContentCategories);
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        setIsRequesting(false);
      });
  }

  function getCategoryValues(values: string): number[] | undefined {
    const valuesStr: string[] = values.length > 0 ? values.split(', ') : [];
    const valuesNum: number[] = valuesStr.map((value: string) => Number.parseInt(value, 10)).sort((a, b) => a - b);

    return valuesNum.length > 0 ? valuesNum : undefined;
  }

  function closeAttachments() {
    setErrorMessage('');
    setSuccessMessage('');
    onClose();
  }

  function handleSelectInputChange(name: string, option: IOption[]) {
    const values = option.map(opt => opt.value).join(', ');
    const labels = option.map(opt => opt.label).join(', ');

    setCategoriesData(prevState => ({
      ...prevState,
      [name]: {
        value: values,
        label: labels,
      },
    }));
  }

  function handleTextInputChange(name: string, value: string) {
    setCategoriesData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  }

  function cancel() {
    buildSubmissionContentCategories();
    closeAttachments();
  }

  return (
    <div className="categories-tab-container" hidden={!open}>
      <div className="categories-tab-header">
        <span className="categories-tab-title">Content Categories</span>
        <IoIosClose size={30} color="#5E677B" onClick={closeAttachments} />
      </div>
      <div className="categories-tab-alerts-container">
        {errorMessage && (
          <AlertComponent text={errorMessage} type="danger" onClick={() => setErrorMessage('')} buttonText="X" />
        )}
        {successMessage && (
          <AlertComponent text={successMessage} type="success" onClick={() => setSuccessMessage('')} buttonText="X" />
        )}
      </div>
      <div className="categories-tab-body">
        <SelectComponent
          label="Primary: "
          name="primary-categories"
          onChange={(option: IOption[]) => handleSelectInputChange('primary', option)}
          isDisabled={false}
          options={allContentCategories}
          selectedOption={
            (categoriesData.primary as IOption).value
              ? (categoriesData.primary as IOption).value.split(', ').map((val, index) => ({
                  value: val,
                  label: (categoriesData.primary as IOption).label.split(', ')[index],
                }))
              : []
          }
          multiple={true}
        />
        {(categoriesData.primary as IOption).label.includes('Other') && (
          <div className="categories-input-container">
            <label htmlFor="primary-other" className="categories-input-label">
              Other:{' '}
            </label>
            <input
              className="cc-input"
              type="text"
              id="primary-other"
              onChange={e => handleTextInputChange('primaryOther', e.target.value)}
              value={categoriesData.primaryOther as string}
            />
          </div>
        )}
        <SelectComponent
          label="Secondary: "
          name="secondary-categories"
          onChange={(option: IOption[]) => handleSelectInputChange('secondary', option)}
          isDisabled={false}
          options={allContentCategories}
          selectedOption={
            (categoriesData.secondary as IOption).value
              ? (categoriesData.secondary as IOption).value.split(', ').map((val, index) => ({
                  value: val,
                  label: (categoriesData.secondary as IOption).label.split(', ')[index],
                }))
              : []
          }
          multiple={true}
        />
        {(categoriesData.secondary as IOption).label.includes('Other') && (
          <div className="categories-input-container">
            <label htmlFor="secondary-other" className="categories-input-label">
              Other:{' '}
            </label>
            <input
              className="cc-input"
              type="text"
              id="secondary-other"
              onChange={e => handleTextInputChange('secondaryOther', e.target.value)}
              value={categoriesData.secondaryOther as string}
            />
          </div>
        )}
        <div className="categories-footer">
          <Button onClick={cancel} className="categories-button-reset" disabled={isRequesting}>
            Cancel
          </Button>
          <Button onClick={saveCategories} disabled={isRequesting} className="categories-button-confirm">
            {isRequesting ? (
              <LoaderComponent styles={{ border: '0.2em solid white', borderTop: '0.2em solid transparent' }} />
            ) : (
              'Save'
            )}
          </Button>
        </div>
      </div>
    </div>
  );
}
