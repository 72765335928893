import { useEffect, useState } from 'react';
import useFeatures from '../resources/features/features-hook';
import { Permission } from '../resources/features/features-types';

export function useAdminStatus(): boolean {
  const { permissions } = useFeatures();
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (permissions && Array.isArray(permissions)) {
      setIsAdmin(permissions.some((permission: Permission) => permission === Permission.AdminAccess));
    }
  }, [permissions]);

  return isAdmin;
}
