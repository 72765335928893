import axios from 'axios';
import { apiWrapper } from '../../config/api-wrapper';
import { ContentCategory, SubmissionContentCategoriesDTO, SubmissionContentCategory } from './content-categories-types';
import { WithAbortFn } from '../../@types/external-api';

const CONTENT_CATEGORIES = '/content-categories';
class ContentCategoriesService {
  public getContentCategories(): WithAbortFn<Promise<ContentCategory[]>> {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.api(CONTENT_CATEGORIES).then((res: any) => res.data),
      abort: source.cancel,
    };
  }

  public getContentCategoriesBySubmissionId(submissionId: number): WithAbortFn<Promise<SubmissionContentCategory[]>> {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.api(`${CONTENT_CATEGORIES}/${submissionId}`).then((res: any) => res.data),
      abort: source.cancel,
    };
  }

  public createSubmissionContentCategories(
    submissionId: number,
    createSubmissionContentCategoriesDTO: SubmissionContentCategoriesDTO,
  ): WithAbortFn<Promise<SubmissionContentCategory[]>> {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.api
        .post(`${CONTENT_CATEGORIES}/${submissionId}/create`, createSubmissionContentCategoriesDTO)
        .then((res: any) => res.data),
      abort: source.cancel,
    };
  }
}

const contentCategoriesService = new ContentCategoriesService();
export default contentCategoriesService;
