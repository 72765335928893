import axios from 'axios';
import { apiWrapper } from '../../config/api-wrapper';
import { WithAbortFn } from '../../@types/external-api';
import { SingFileToUpload } from './upload-types';

const UPLOAD_ENDPOINT = '/upload';
class UploadService {
  public signFile(submissionId: number, fileName: string): WithAbortFn<Promise<SingFileToUpload>> {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.api
        .post(`${UPLOAD_ENDPOINT}/${submissionId}/review-file/sign?fileName=${fileName}`)
        .then((res: any) => res.data),
      abort: source.cancel,
    };
  }

  public gcsPathUpdate(submissionId: number, gcsPath: string): WithAbortFn<Promise<void>> {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.api
        .post(`${UPLOAD_ENDPOINT}/${submissionId}/gcs-path/update?gcsPath=${gcsPath}`)
        .then((res: any) => res.data),
      abort: source.cancel,
    };
  }
}

const uploadService = new UploadService();
export default uploadService;
