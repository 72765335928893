import React, { ReactNode } from 'react';
import { FiAlertTriangle, FiInfo } from 'react-icons/fi';

import './index.scss';

type AlertType = 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark';

interface AlertComponentProps {
  text: string | ReactNode;
  type?: AlertType;
  onClick?: (event: React.MouseEvent<HTMLSpanElement>) => void;
  buttonText?: string;
}

export default function AlertComponent({ text, type, onClick, buttonText }: AlertComponentProps) {
  const renderIcon = () => {
    switch (type) {
      case 'danger':
        return <FiAlertTriangle size={16} />;
      case 'info':
        return <FiInfo size={16} />;
      default:
        return null;
    }
  };

  return (
    <div className={`alert-container ${type}`}>
      <div className="alert-content">
        {renderIcon()}
        <span className="alert-text">{text}</span>
      </div>
      {buttonText && (
        <span className="alert-button" onClick={onClick}>
          {buttonText}
        </span>
      )}
    </div>
  );
}
