import './index.scss';
import { ChangeEvent, DragEvent, ReactNode, useEffect, useState } from 'react';
import { IoIosClose } from 'react-icons/io';
import { MdDownload, MdRefresh } from 'react-icons/md';
import {
  FaFileAlt,
  FaFileUpload,
  FaRegTrashAlt,
  FaFileImage,
  FaFileVideo,
  FaFilePdf,
  FaFileAudio,
  FaFileCsv,
  FaFileExcel,
  FaFileWord,
} from 'react-icons/fa';
import { FaFileZipper } from 'react-icons/fa6';
import { GoDotFill } from 'react-icons/go';
import { FiInfo } from 'react-icons/fi';
import { Tooltip } from 'react-tooltip';
import { SubmissionDetailsDTO } from '../../../interface/submission-details-dto';
import { useAppSelector } from '../../../redux/hooks';
import { Attachment } from '../../../interface/attachment';
import attachmentService from '../../../resources/attachments/attachment.service';
import { Button } from 'react-bootstrap';
import { AttachmentDTO } from '../../../interface/attachment-dto';
import { FileExtended } from '../../../interface/file-extended';
import AlertComponent from '../../../components/ui/alert';
import LoaderComponent from '../../../components/ui/loader';
import { getMimeType, hasSubmissionError } from '../../../helpers/submission-helper';
import { formatDate } from '../../../helpers/date-helper';
import { FileStatus } from '../../../enum/file-status';
import { formatBytes, formatFileName } from '../../../helpers/file-helper';
import { useAdminStatus } from '../../../hooks/permissions-hook';

interface AttachmentTabProps {
  isOpened: boolean;
  onClose: () => void;
  onCancel: () => void;
}

export function selectFileIcon(fileType: string): ReactNode {
  const iconMap: { [key: string]: ReactNode } = {
    image: <FaFileImage size={20} color="#5E677B" />,
    video: <FaFileVideo size={20} color="#5E677B" />,
    pdf: <FaFilePdf size={20} color="#5E677B" />,
    zip: <FaFileZipper size={20} color="#5E677B" />,
    audio: <FaFileAudio size={20} color="#5E677B" />,
    csv: <FaFileCsv size={20} color="#5E677B" />,
    spreadsheet: <FaFileExcel size={20} color="#5E677B" />,
    word: <FaFileWord size={20} color="#5E677B" />,
    default: <FaFileAlt size={20} color="#5E677B" />,
  };

  return iconMap[Object.keys(iconMap).find(key => fileType.includes(key)) || 'default'];
}

export default function AttachmentTab({ isOpened, onClose, onCancel }: AttachmentTabProps) {
  const [filesToBeUploaded, setFilesToBeUploaded] = useState<FileExtended[]>([]);
  const [attachments, setAttachments] = useState<AttachmentDTO[]>([]);
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [secErrorMessage, setSecErrorMessage] = useState<string>('');
  const [isRequestingAttachments, setIsRequestingAttachments] = useState<boolean>(false);
  const [isDeletingAttachment, setIsDeletingAttachment] = useState<Record<number, boolean>>({});

  const currentRecord: SubmissionDetailsDTO = useAppSelector(state => state.submission.currentRecord);
  const { isTranscriptExtended } = useAppSelector(state => state.submission);
  const isAdmin = useAdminStatus();

  useEffect(() => {
    fetchAttachments();
  }, []);

  useEffect(() => {
    fetchAttachments();
  }, [currentRecord.recordId]);

  useEffect(() => {
    if (isTranscriptExtended) {
      onClose();
    }
  });

  function handleFileChange(event: ChangeEvent<HTMLInputElement>) {
    event.preventDefault();
    const files = event.target.files;

    addFiles(files);
  }

  function handleDrop(event: DragEvent) {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;

    addFiles(droppedFiles);
  }

  function addFiles(files: FileList | null) {
    setErrorMessage('');

    if (hasSubmissionError(currentRecord)) {
      setErrorMessage('Unable to attach files to a submission with error');
      return;
    }

    if (files && files.length > 0) {
      const newFiles: FileExtended[] = Array.from(files).map((file: File) => ({
        file,
        progress: 0,
        status: FileStatus.PENDING,
      }));
      const filteredOverSizedFiles = filterOverSizedFiles(newFiles);
      const filteredDuplicatedFiles = filterDuplicateFiles([...filesToBeUploaded, ...filteredOverSizedFiles]);

      setFilesToBeUploaded(filteredDuplicatedFiles);
    }
  }

  function filterOverSizedFiles(files: FileExtended[]) {
    const maxFileSize = 10000000;
    const filteredFiles = files.filter((file: FileExtended) => file.file.size <= maxFileSize);
    const hasFileOverLimit = files.length !== filteredFiles.length;

    if (hasFileOverLimit) {
      setErrorMessage('One or more files are larger than 10 MB and have not been added to the upload list');
    }

    return filteredFiles;
  }

  function filterDuplicateFiles(files: FileExtended[]): FileExtended[] {
    const seenNames = new Set<string>();

    return files.filter((fileExtended: FileExtended) => {
      if (seenNames.has(fileExtended.file.name)) {
        setSecErrorMessage('Some files were not added to the upload list due to duplicate names.');
        return false; // Duplicate found
      }

      seenNames.add(fileExtended.file.name);

      return true; // Unique file
    });
  }

  function uploadFile(fileExtended: FileExtended) {
    setErrorMessage('');

    const attachment: Attachment = {
      submissionId: currentRecord.submissionId,
      fileName: fileExtended.file.name,
      fileType: getMimeType(fileExtended.file.name),
      fileSize: fileExtended.file.size,
    };

    updateFileStatus(fileExtended, FileStatus.UPLOADING);

    attachmentService
      .signAttachment(attachment)
      .promise.then((res: any) => {
        const signedUrl: string = res.signedUrl;
        const attachmentId: number = res.attachmentId;
        const xhr = new XMLHttpRequest();

        xhr.open('PUT', signedUrl, true);
        xhr.setRequestHeader('Content-Type', attachment.fileType);

        xhr.upload.onprogress = event => updateFileProgress(event, fileExtended);

        xhr.onload = () => {
          if (xhr.status === 200) {
            attachmentService
              .updateStatusToUploaded(attachmentId)
              .promise.then((attachment: AttachmentDTO) => {
                addAttachment(attachment);
                handleUploadCompletion();
              })
              .catch(error => console.error(error));
            updateFileStatus(fileExtended, FileStatus.UPLOADED);
          } else {
            attachmentService
              .updateStatusToFailed(attachmentId)
              .promise.then(() => {
                handleUploadCompletion();
              })
              .catch(error => console.error(error));
            updateFileStatus(fileExtended, FileStatus.FAILED);
          }
        };

        xhr.onerror = () => {
          attachmentService.updateStatusToFailed(attachmentId).promise.catch(error => console.error(error));
          updateFileStatus(fileExtended, FileStatus.FAILED);
        };

        xhr.send(fileExtended.file);
      })
      .catch(error => {
        const errorMessage: string = error.response.data.message;

        if (errorMessage && errorMessage.includes('Attachment already exists')) {
          fileExtended.error = 'Duplicate';
          setErrorMessage('Duplicate files need to be renamed');
        } else {
          console.error(error);
        }

        updateFileStatus(fileExtended, FileStatus.FAILED);
      });
  }

  function handleUploadCompletion() {
    if (haveAllFilesBeenUploaded() && !someFileIsBeingProcessed()) {
      setSuccessMessage('Success! All your files have been uploaded.');
      removeUploadedFiles();
    } else if (isThereAnyFileUploaded() && !someFileIsBeingProcessed()) {
      setSuccessMessage('Some files have been uploaded successfully!');
      removeUploadedFiles();
    }
  }

  function addAttachment(newAttachment: AttachmentDTO) {
    setAttachments(prevAttachments => [...prevAttachments, newAttachment]);
  }

  function updateFileProgress(event: ProgressEvent<EventTarget>, fileExtended: FileExtended) {
    if (event.lengthComputable) {
      const percentComplete = Math.round((event.loaded / event.total) * 100);
      fileExtended.progress = percentComplete;

      updateFile(fileExtended);
    }
  }

  function updateFile(fileExtended: FileExtended) {
    if (filesToBeUploaded) {
      const filesToBeUploadedCopy = [...filesToBeUploaded];
      const fileIndex = filesToBeUploadedCopy.indexOf(fileExtended);

      if (fileIndex > -1) {
        filesToBeUploadedCopy[fileIndex] = fileExtended;
      }

      setFilesToBeUploaded(filesToBeUploadedCopy);
    }
  }

  function updateFileStatus(fileExtended: FileExtended, status: string) {
    fileExtended.status = status;
    updateFile(fileExtended);
  }

  function haveAllFilesBeenUploaded(): boolean {
    return filesToBeUploaded.every(fileExtended => fileExtended.status === FileStatus.UPLOADED);
  }

  function isThereAnyFileUploaded(): boolean {
    return filesToBeUploaded.some(fileExtended => fileExtended.status === FileStatus.UPLOADED);
  }

  function someFileIsBeingUploaded(): boolean {
    return filesToBeUploaded.some(fileExtended => fileExtended.status === FileStatus.UPLOADING);
  }

  function someFileIsPending(): boolean {
    return filesToBeUploaded.some(fileExtended => fileExtended.status === FileStatus.PENDING);
  }

  function someFileIsBeingProcessed(): boolean {
    return someFileIsBeingUploaded() || someFileIsPending();
  }

  function uploadFiles(filesParam: FileExtended[]) {
    closeErrorAlert();

    if (filesParam.length > 0) {
      for (let index = 0; index < filesParam.length; index++) {
        const file = filesParam[index];

        if (file.status === FileStatus.UPLOADED) {
          continue;
        }

        uploadFile(file);
      }
    }
  }

  function fetchAttachments() {
    if (isRequestingAttachments) {
      return;
    }
    setIsRequestingAttachments(true);

    attachmentService
      .getAttachments(currentRecord.submissionId)
      .promise.then((attachments: AttachmentDTO[]) => {
        setAttachments(attachments);
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        setIsRequestingAttachments(false);
      });
  }

  function removeFile(fileExtended: FileExtended): void {
    if (filesToBeUploaded.length > 0) {
      const filesToBeUploadedCopy = [...filesToBeUploaded];
      const fileIndex = filesToBeUploadedCopy.indexOf(fileExtended);

      if (fileIndex > -1) {
        filesToBeUploadedCopy.splice(fileIndex, 1);
      }

      setFilesToBeUploaded(filesToBeUploadedCopy);
    }
  }

  function removeUploadedFiles(): void {
    const nonUploadedFiles = filesToBeUploaded.filter((file: FileExtended) => file.status !== FileStatus.UPLOADED);

    setFilesToBeUploaded(nonUploadedFiles);
  }

  function deleteAttachment(attachmentId: number) {
    const isAnyDeleting = Object.values(isDeletingAttachment).some(isDeleting => isDeleting);

    if (isAnyDeleting) {
      return;
    }

    setIsDeletingAttachment(prev => ({ ...prev, [attachmentId]: true }));

    attachmentService
      .deleteAttachment(attachmentId)
      .promise.then(() => {
        const deletedAtachment = attachments.find((attachment: AttachmentDTO) => attachment.id === attachmentId);

        if (deletedAtachment) {
          removeAttachment(deletedAtachment);
        }
      })
      .finally(() => {
        setIsDeletingAttachment(prev => ({ ...prev, [attachmentId]: false }));
      });
  }

  function removeAttachment(attachment: AttachmentDTO): void {
    if (attachments.length > 0) {
      const attachmentsCopy = [...attachments];
      const attachmentIndex = attachmentsCopy.indexOf(attachment);

      if (attachmentIndex > -1) {
        attachmentsCopy.splice(attachmentIndex, 1);
      }

      setAttachments(attachmentsCopy);
    }
  }

  function cancelUpload() {
    setFilesToBeUploaded([]);
    clearAlertMessages();
    onCancel();
  }

  function clearAlertMessages() {
    setErrorMessage('');
    setSecErrorMessage('');
    setSuccessMessage('');
  }

  function refreshUpload(fileExtended: FileExtended) {
    uploadFile(fileExtended);
  }

  function closeSuccessAlert() {
    setSuccessMessage('');
  }

  function closeErrorAlert() {
    setErrorMessage('');
  }

  function closeSecErrorAlert() {
    setSecErrorMessage('');
  }

  function closeAttachments() {
    clearAlertMessages();
    onClose();
  }

  function countFilesMissingUpload(): number {
    return filesToBeUploaded.filter((file: FileExtended) => file.status !== FileStatus.UPLOADED).length;
  }

  return (
    <div className="attachments-tab-container" hidden={!isOpened}>
      <div className="attachments-tab-header">
        <span className="attachments-tab-title">Attachments</span>
        <IoIosClose size={30} color="#5E677B" onClick={closeAttachments} />
      </div>
      <div className="attachments-tab-body">
        <div className="attachments-tab-upload">
          {successMessage && (
            <AlertComponent text={successMessage} type="success" onClick={closeSuccessAlert} buttonText="X" />
          )}
          {errorMessage && (
            <AlertComponent text={errorMessage} type="danger" onClick={closeErrorAlert} buttonText="X" />
          )}
          {secErrorMessage && (
            <AlertComponent text={secErrorMessage} type="danger" onClick={closeSecErrorAlert} buttonText="X" />
          )}
          <div className="upload-drop-zone" onDrop={handleDrop} onDragOver={event => event.preventDefault()}>
            <FaFileUpload size={24} color="#5E677B" />
            <div className="upload-drop-zone-text">
              <span>
                Drag & drop your files here or &nbsp;
                <label htmlFor="file-input" className="upload-choose-file-button">
                  choose files
                </label>
                <input
                  className="upload-choose-file-input"
                  type="file"
                  id="file-input"
                  multiple
                  onChange={handleFileChange}
                />
                .
              </span>
              <span className="upload-drop-zone-max-size">
                <FiInfo size={14} />
                Max file size: <strong>10 MB</strong>
              </span>
            </div>
          </div>
          <div className="tab-upload-files">
            {filesToBeUploaded.length > 0 && (
              <div className="tab-upload-files-title-container">
                <span className="tab-upload-files-title">{`Files to upload (${countFilesMissingUpload()})`}</span>
                <span onClick={() => setFilesToBeUploaded([])} className="tab-upload-files-action">
                  <em>remove all</em>
                </span>
              </div>
            )}
            {filesToBeUploaded.length > 0 && (
              <div className="files-upload-cards">
                {filesToBeUploaded.map((fileExtended: FileExtended, index: number) => (
                  <div className="files-upload-card" key={index}>
                    <div className="files-upload-card-body">
                      {selectFileIcon(fileExtended.file.type)}
                      <div className="files-upload-card-content">
                        <span
                          className="files-upload-card-title"
                          data-tooltip-id="file-name-tooltip"
                          data-tooltip-content={fileExtended.file.name.length > 45 ? fileExtended.file.name : ''}>
                          {formatFileName(fileExtended.file.name)}
                        </span>
                        <div className="files-upload-card-infos">
                          <span className="files-upload-card-info">{formatBytes(fileExtended.file.size, 1)}</span>
                          {fileExtended.status === FileStatus.FAILED && (
                            <>
                              <GoDotFill size={6} color="#5E677B" />
                              <span className="files-upload-card-error">{`${
                                fileExtended.error ? fileExtended.error : 'Upload failed'
                              }`}</span>
                            </>
                          )}
                          {fileExtended.status === FileStatus.UPLOADED && (
                            <>
                              <GoDotFill size={6} color="#5E677B" />
                              <span className="files-upload-card-completed">Upload completed</span>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="files-upload-card-actions">
                        {fileExtended.status !== FileStatus.UPLOADING && (
                          <FaRegTrashAlt size={18} color="#5E677B" onClick={() => removeFile(fileExtended)} />
                        )}
                        {fileExtended.status === FileStatus.FAILED && (
                          <MdRefresh size={22} color="#5E677B" onClick={() => refreshUpload(fileExtended)} />
                        )}
                      </div>
                    </div>
                    {fileExtended.status === FileStatus.UPLOADING && (
                      <div className="progress-bar-track">
                        <div
                          id="myBar"
                          className="progress-bar"
                          style={{
                            width: `${fileExtended.progress || 0}%`,
                          }}></div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="tab-upload-actions">
            <Button onClick={() => cancelUpload()} className="tab-upload-button-cancel">
              Cancel
            </Button>
            <Button
              onClick={() => uploadFiles(filesToBeUploaded)}
              disabled={filesToBeUploaded.length === 0 || someFileIsBeingUploaded() || haveAllFilesBeenUploaded()}
              className="tab-upload-button-upload">
              Upload
            </Button>
          </div>
          <p className="tab-upload-notice">
            <em>Once uploaded, the attachment can no longer be deleted.</em>
          </p>
        </div>
        <div className="attachments-tab-files">
          {isRequestingAttachments ? (
            <LoaderComponent styles={{ margin: '0 auto', padding: '14px' }} />
          ) : attachments && attachments.length > 0 ? (
            <div className="files-cards">
              {attachments?.map((attachment: AttachmentDTO, index: number) => (
                <div className="files-card" key={index}>
                  {selectFileIcon(attachment.fileType)}
                  <div className="files-card-content">
                    <span
                      data-tooltip-id="file-name-tooltip"
                      data-tooltip-content={attachment.fileName.length > 45 ? attachment.fileName : ''}
                      className="files-card-title">
                      {formatFileName(attachment.fileName)}
                    </span>
                    <div className="files-card-infos">
                      <span>{formatDate(attachment.createdAt, 'YYYY-MM-DD')}</span>
                      <GoDotFill size={6} color="#5E677B" />
                      <span>{attachment.createdBy}</span>
                      <GoDotFill size={6} color="#5E677B" />
                      <span>{formatBytes(attachment.fileSize, 1)}</span>
                    </div>
                  </div>
                  <div className="files-card-actions">
                    <a href={attachment.singedSourceUrl} target="_blank">
                      <MdDownload size={22} color="#5E677B" />
                    </a>
                    {isAdmin && (
                      <div className="delete-action-container">
                        {isDeletingAttachment[attachment.id] ? (
                          <LoaderComponent styles={{ borderColor: '#5E677B', borderTop: '0.2em solid transparent' }} />
                        ) : (
                          <FaRegTrashAlt
                            className="delete-action"
                            color="#5E677B"
                            onClick={() => deleteAttachment(attachment.id)}
                          />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p className="no-attachments">There are no attachments to show</p>
          )}
        </div>
      </div>
      <Tooltip id="file-name-tooltip" place="top" />
    </div>
  );
}
