export interface SubmissionContentCategoriesDTO {
  primaryCategories?: number[];
  primaryOther?: string;
  secondaryCategories?: number[];
  secondaryOther?: string;
}

export interface SubmissionContentCategory {
  id: number;
  submissionId: number;
  categoryId: number;
  categoryType: string;
  comment: string;
  createdAt: Date;
  createdBy: String;
  updatedAt: Date;
  updatedBy: String;
}

export interface ContentCategory {
  id: number;
  name: string;
  createdAt: Date;
  createdBy: String;
  updatedAt: Date;
  updatedBy: String;
}

export enum CategoryTypes {
  primary = 'primary',
  secondary = 'secondary',
}
